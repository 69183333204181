<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manual Enrolment for Practical Exams</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manual Enrolment
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <v-row>
              <v-col cols="12" md="12">
                <div class="my-1">
                  <h6>Enter the enroller name or email</h6>
                  <v-select
                            @change="handleChange"
                            :items="acHoldersEmail"
                            item-text="text"
                            dense
                            outlined
                            item-value="value"
                            label="Select enroller"
                            required
                  ></v-select>

                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  data() {
    return {
      acHoldersEmail: ['test@test.edu.au', 'testuse@123mail.com', 'lorem23@gmail.com']
    }
  },
  methods: {
    handleChange(){
      this.$router.push({
        name : 'manual-enrolment-step2'
      });
    }
  }
}
</script>
